export const TRANSLATIONS_EN = {
  search: "Search",
  booking: "Booking",
  not_found:
    "Sorry, there are no products to display. Please revisit this page again soon. 😉",
  all_categories: "All Categories",
  accommodation: "Accommodation",
  activity: "Activity",
  restaurant: "Restaurant",
  produce: "Produce",
  search_keyword: "Type Keywords",
  view_details: "View",
  basic_info: "Basic Information",
  map: "Map",
  details: "Details",
  home: "Home",
  list: "List",
  name: "Name",
  address: "Address",
  phone: "Phone",
  website: "Webiste",
  email: "Email",
  fax: "Fax",
  from: "From",
  price: "Price",
  date: "Date",
  rate: "Rate",
  price_range: "Price Range",
  keyword: "Type Keyword",
  available_products: "Available Products",
  book_now: "Book Now",
  check_price: "Check Price & Availability",
  cart_empty: "Cart is Empty",
  first_name: "First Name",
  last_name: "Last Name",
  re_email: "Retype Email Address",
  mobile: "Mobile/Cell phone",
  city: "Suburb/City",
  state: "State/Country",
  country: "Country",
  zip: "Zip/Postal Code",
  type_keywords: "Type Keywords",
  product: "Product",
  options: "Options/Cost",
  totals: "Totals",
  special_requests: "Special Requests",
  quick_booking: "Quick Booking",
  request_book: "Request to Book",
  customer_detail: "Customer Detail",
  load_more: "Load More",
  request_to_book: "Request to Book",
  start_time: "Start time: {{ time }}, or choose a different time",
  continue_payment: "Continue to Payment",
  close: "Close",
  quantity: "Quantity",
  see_more: "See More",
  modal_header: "Make a booking request",
  modal_desc:
    "Please note, this booking needs to be confirmed with the operator.<br><br>You'll receive an email within 24 hours to confirm if your booking has been accepted and if so you can pay securely online",
  confirm: "Confirm",
  submit: "Submit",
  items: "Item(s)",
  go_back: "Go Back",
  booking_req_detail_wait: "Booking Request Details - awaiting confirmation",
  booking_req_instructions:
    "You'll receive an email within 24 hours to confirm if your booking request has been accepted by the operator. Payment is not due until your booking request has been accepted.",
  request_date: "Request Date (UTC)",
  booking_reference: "Booking Reference",
  booked_by: "Booked by",
  supplier_information: "Supplier Information",
  total_gst: "Total (inc. GST)",
  amount_paid: "Amount Paid (inc. GST)",
  amount_owing: "Amount Owing (inc. GST)",
  adult: "Adult(s)",
  check_in: "Check In",
  check_out: "Check Out",
  not_found_page: "Not Found",
  booking_date: "Booking Date",
};
