export function getUrlParam(name = "", url = "") {
  if (!url) url = window.location.href;

  name = name.replace(/[\[\]]/g, "\\$&"); /* eslint-disable-line */

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return "";

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function formatMoney(value, thousandSeparator = ",") {
  return value.toLocaleString("en").replace(/,/g, thousandSeparator);
}
